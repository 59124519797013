import React, { useState } from "react";
import styled from "styled-components";
import locationPin from "../../assets/icons/location-pin.png";

import {
  FlexColumn,
  FlexRow,
  StyledBodyText,
  StyledH5,
  StyledLabelText,
  ATag,
} from "../../styles";
import { SDGMapper } from "./utils";

const Container = styled(FlexColumn)`
  width: 368px;
  max-height: 529px;
  min-height: 529px;
  background-color: #ffffff;
  border-radius: 16px;
`;

type HeaderProps = { image: string; isNotFound: boolean };

export const Header = styled(FlexRow)`
  background-image: ${(props: HeaderProps) => `url(${props.image})`};
  background-repeat: no-repeat;
  background-size: ${(props: HeaderProps) =>
    props.isNotFound ? "30%" : "contain"};
  background-position: ${(props: HeaderProps) =>
    props.isNotFound ? "center" : "top"};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  height: 256px;
`;

export const Body = styled(FlexColumn)`
  max-height: 273px;
  padding: 16px 8px 16px;
  flex: 1;
`;

const ProjectName = styled(StyledH5)`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  height: 36px;
`;

const PinIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  margin-left: -3px;
`;

const DescriptionContainer = styled(FlexColumn)`
  width: 100%;
  height: calc(6rem + 8px);
  overflow: hidden;
  white-space: normal;
  margin-bottom: 4px;
`;

const Description = styled(StyledBodyText)`
  color: rgba(90, 90, 90, 0.8);
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
`;

const SDGLabel = styled(StyledLabelText)`
  color: rgba(90, 90, 90, 0.8);
  letter-spacing: -0.02em;
  padding-bottom: 8px;
`;

const SDGIcon = styled.img`
  margin-right: 8px;
  width: 40px;
  height: 40px;
`;

const SDGIconMore = styled(FlexRow)`
  width: 40px;
  height: 40px;
  background-color: #f7f7f7;
  align-items: center;
  justify-content: center;
`;

const Location = styled(FlexRow)`
  margin-bottom: 8px;
  height: 24px;
`;

const SDGContainer = styled(FlexColumn)`
  height: 66px;
`;

type Props = {
  sdg?: Array<number>;
  name: string;
  description: string | null;
  location: string | undefined;
  imageUrl: string;
  websiteUrl: string;
};

function ProjectCard({
  sdg = [],
  name,
  description,
  location,
  imageUrl,
  websiteUrl,
}: Props) {
  const [sdgList, setSdgList] = useState<{
    visible: Array<number>;
    nonVisibleCount: number | null;
  }>({ visible: [], nonVisibleCount: null });

  useState(() => {
    if (sdg && sdg.length > 7) {
      setSdgList({ visible: sdg.slice(0, 6), nonVisibleCount: sdg.length - 6 });
    }

    if (sdg && sdg.length <= 7) {
      setSdgList({ visible: sdg, nonVisibleCount: null });
    }
  });

  return (
    <Container>
      <ATag
        href={`https://earthly.org/${websiteUrl}`}
        target="_blank"
        rel="noreferrer"
      >
        <Header
          image={imageUrl}
          isNotFound={imageUrl.includes("not-found")}
          data-testid={`${name} image`}
        />
        <Body>
          <ProjectName>{name}</ProjectName>
          {location && (
            <Location data-testid="location-container">
              <PinIcon src={locationPin} alt="location pin" />
              <StyledBodyText>{location}</StyledBodyText>
            </Location>
          )}
          <DescriptionContainer>
            <Description>{description}</Description>
          </DescriptionContainer>

          <SDGContainer>
            {sdgList.visible.length > 0 && (
              <SDGLabel>UN&apos;s Sustainable Development Goals</SDGLabel>
            )}
            <FlexRow>
              {sdgList?.visible.map((singleSdg) => (
                <SDGIcon
                  key={singleSdg}
                  src={SDGMapper[singleSdg]}
                  alt={`sdg-${singleSdg}`}
                  data-testid="sdg-icons"
                />
              ))}
              {sdgList?.nonVisibleCount && (
                <SDGIconMore data-testid="more-sdg">
                  <StyledBodyText>{`+${sdgList.nonVisibleCount}`}</StyledBodyText>
                </SDGIconMore>
              )}
            </FlexRow>
          </SDGContainer>
        </Body>
      </ATag>
    </Container>
  );
}

export default ProjectCard;
