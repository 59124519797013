import { fromLonLat } from "ol/proj";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import XYZ from "ol/source/XYZ";
import { ScaleLine, defaults as defaultControls } from "ol/control";

export const initialCenterCoordinates: [number, number] = [23.91, 16.2];
export const initialCoordMercator = fromLonLat(initialCenterCoordinates);
export const initialZoom = 0;
export const projectionForCenter = "EPSG:3857";

const url =
  "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";
const satelliteLayer = new TileLayer({
  preload: Infinity,
  maxZoom: 19,
  source: new XYZ({
    url,
    attributions: `ESRI, ${url}`,
  }),
});

const scaleControl = new ScaleLine({
  units: "us",
  className: "custom-scale-line",
});

const desktopMapControls = defaultControls().extend([scaleControl]);

export const currentMap = new Map({
  target: "map-container",
  layers: [satelliteLayer],
  controls: desktopMapControls,
  view: new View({
    center: initialCoordMercator,
    zoom: initialZoom,
    constrainResolution: true,
    maxZoom: 13,
    projection: projectionForCenter,
  }),
});

export type Coordinates = { lat: number; lon: number };
export type Coordinate = [number, number];
export type PolygonCoordinates = Coordinate[][];
export type MultiPolygonCoordinates = PolygonCoordinates[];
export type Images = { default: string };

export type Geometry = {
  type: "Polygon" | "MultiPolygon";
  coordinates: PolygonCoordinates | MultiPolygonCoordinates;
};

export type BiodiversityPolygon = {
  type: "Feature";
  geometry: Geometry;
  properties?: {
    whatThreeWordsPoint: string;
    spatialIndex?: number;
  };
};

export type Intervention = {
  action: Array<string>;
  ecosystem: Array<string>;
};

export type Outcomes = {
  unit: string;
  value: number;
  isUnitOfSale: boolean;
};

export type UnitData = {
  geometry: {
    type: "FeatureCollection";
    features: BiodiversityPolygon[];
  };
  unitCount: number;
  clientUnitTotalArea: number;
};

export type ProjectResponse = {
  id: string;
  name: string;
  description: string;
  country: string;
  websiteUrl: string;
  images: Images;
  SDG?: Array<number>;
  interventions: Array<Intervention>;
  outcomes: Array<Outcomes>;
  centerCoordinates: Coordinates;
  zoomLevel: number;
  unitData?: UnitData;
};

export type MergedProjectResponse = {
  id: string;
  name: string;
  description: string;
  country: string;
  websiteUrl: string;
  images: Images;
  SDG?: Array<number>;
  interventions: Array<Intervention>;
  outcomes: Array<Outcomes>;
  centerCoordinates: Coordinates;
  zoomLevel: number;
  unitData?: UnitData;
  mainImpact: Array<ImpactType>;
  coBenefits: Array<ImpactType>;
  projectId: string;
};

export type ImpactType = {
  unit: string;
  value: number;
};

export type IndividualProjectResponse = {
  projectId: string;
  mainImpact: Array<ImpactType>;
  coBenefits: Array<ImpactType>;
};

export type ImpactResponse = {
  total: {
    mainImpact: Array<ImpactType>;
    coBenefits: Array<ImpactType>;
  };
  perProject: Array<IndividualProjectResponse>;
};
export interface MapContexts {
  mapRef: React.RefObject<Map>;
  projects: MergedProjectResponse[];
  setProjects: (projects: MergedProjectResponse[]) => void;
  showMap: boolean;
  showDropdownMenu: boolean;
  setShowDropdownMenu: (show: boolean) => void;
  showPopup: boolean;
  setShowPopup: (show: boolean) => void;
  popupInfo: MergedProjectResponse;
  setPopupInfo: React.Dispatch<React.SetStateAction<MergedProjectResponse>>;
  projectHighlight: MergedProjectResponse | undefined;
  setProjectHighlight: React.Dispatch<
    React.SetStateAction<MergedProjectResponse | undefined>
  >;
  isMobile: boolean;
  dataId: string;
}

export const defaultContext: MapContexts = {
  mapRef: { current: currentMap },
  projects: [],
  setProjects: () => {},
  showMap: false,
  showDropdownMenu: false,
  setShowDropdownMenu: () => {},
  projectHighlight: undefined,
  setProjectHighlight: () => {},
  showPopup: false,
  setShowPopup: () => {},
  popupInfo: {
    id: "",
    name: "",
    description: "",
    country: "",
    images: {
      default: "",
    },
    SDG: [],
    websiteUrl: "",
    centerCoordinates: { lat: 0, lon: 0 },
    zoomLevel: 8,
    outcomes: [
      {
        unit: "",
        value: 0,
        isUnitOfSale: false,
      },
    ],
    interventions: [
      {
        action: [],
        ecosystem: [],
      },
    ],
    projectId: "",
    mainImpact: [
      {
        unit: "",
        value: 0,
      },
    ],
    coBenefits: [
      {
        unit: "",
        value: 0,
      },
    ],
  },
  setPopupInfo: () => {},
  isMobile: false,
  dataId: "",
};
