import GeoJSON from "ol/format/GeoJSON";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import { Fill, Stroke, Style } from "ol/style";
import { MergedProjectResponse } from "../map-setup-context/InitialMapValues";
import getProjectFillColour from "./utils";

export type Coordinates = { lat: number; lon: number };
export type Coordinate = [number, number];
export type PolygonCoordinates = Coordinate[][];
export type MultiPolygonCoordinates = PolygonCoordinates[];
type CRSProperties = {
  name?: string;
};

type GeoJSONCRS = {
  type: string;
  properties?: CRSProperties;
};

type GeoJSONFeature = {
  type: "Feature";
  geometry: {
    type: "MultiPolygon";
    coordinates: MultiPolygonCoordinates;
  };
  properties: {
    whatThreeWordsPoint: string;
  };
};

type FeatureCollection = {
  type: "FeatureCollection";
  features: GeoJSONFeature[];
  crs?: GeoJSONCRS;
};

const getProjectHighlightLayer = (project: MergedProjectResponse) => {
  const projectType = project.interventions[0].ecosystem[0];
  const projectFillColour = getProjectFillColour(projectType) || "#40E4AB";
  const geoJson = project.unitData?.geometry as FeatureCollection | undefined;
  const incomingCRS = geoJson?.crs?.properties?.name || "EPSG:4326";

  const vectorSource = new VectorSource({
    format: new GeoJSON(),
    features: new GeoJSON().readFeatures(geoJson, {
      dataProjection: incomingCRS,
      featureProjection: "EPSG:3857",
    }),
  });

  const style = new Style({
    fill: new Fill({ color: `${projectFillColour}d1` }),
    stroke: new Stroke({
      color: projectFillColour,
      width: 1,
    }),
  });

  const vectorLayer = new VectorLayer({
    source: vectorSource,
    className: "highlight-layer",
    style,
  });

  return vectorLayer;
};

export default getProjectHighlightLayer;
