import React from "react";
import balance from "../../assets/icons/balance.png";
import m2 from "../../assets/icons/m2.png";
import trees from "../../assets/icons/trees.png";
import seaweed from "../../assets/icons/seaweed.png";
import biodiversity from "../../assets/icons/biodiversity.png";

export const outcomeDataMapper = (unit: string) =>
  [
    {
      unit: "Tonnes CO2",
      description: (
        <span>
          Tonnes of CO<sub>2</sub>
        </span>
      ),
      icon: balance,
    },
    {
      unit: "m2",
      description: (
        <span>
          m<sup>2</sup> of land supported
        </span>
      ),
      icon: m2,
    },
    {
      unit: "Tree(s)",
      description: <span>Trees</span>,
      icon: trees,
    },
    {
      unit: "m3 Kelp",
      description: (
        <span>
          m<sup>3</sup> of kelp
        </span>
      ),
      icon: seaweed,
    },
    {
      unit: "Biodiversity",
      description: <span>Biodiversity Credits</span>,
      icon: biodiversity,
    },
  ].find((outcome) => outcome.unit === unit);

export const formatValueToShort = (value: number) => {
  return Intl.NumberFormat("en-GB", {
    notation: "compact",
    maximumFractionDigits: 2,
  })
    .format(value)
    .toLowerCase();
};
