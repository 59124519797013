import React from "react";
import styled from "styled-components";
import DefaultErrorImage from "../../assets/icons/magnifier.png";

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ErrorImage = styled.img`
  width: 100px;
  height: 100px;
  margin-bottom: 16px;
`;

type ErrorImageComponentProps = {
  imageSrc?: string;
};

function ErrorImageComponent({
  imageSrc = DefaultErrorImage,
}: ErrorImageComponentProps) {
  return (
    <CenteredContainer>
      <ErrorImage src={imageSrc} alt="Error" />
    </CenteredContainer>
  );
}

export default ErrorImageComponent;
