import getImpact from "./getImpact";
import getProjects from "./getProjects";
import getClientDetails from "./getClientDetails";
import getTransactions from "./getTransactions";

const api = {
  getImpact,
  getProjects,
  getClientDetails,
  getTransactions,
};

export default api;
