import * as React from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import ErrorPage from "./ErrorPage";
import Container from "./Container";
import MapProvider from "./components/impact-map/map-setup-context/MapContext";

function FallbackErrorPage(props: FallbackProps) {
  const { error } = props as { error: string };

  return <ErrorPage message={error} />;
}

function App() {
  return (
    <ErrorBoundary FallbackComponent={FallbackErrorPage}>
      <MapProvider>
        <Container />
      </MapProvider>
    </ErrorBoundary>
  );
}

export default App;
