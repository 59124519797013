import React from "react";
import styled from "styled-components";
import { FlexColumn, FlexRow, StyledH2 } from "../../styles";
import ErrorImageComponent from "./ErrorImage";
import ErrorMessageComponent from "./ErrorMessage";

const ErrorSectionContainer = styled(FlexColumn)`
  margin-bottom: 64px;
  justify-content: center;
`;

const SectionErrorWrapper = styled(FlexRow)`
  justify-content: center;
  padding-top: 32px;
  flex-wrap: wrap;
`;

const SectionErrorHeader = styled(StyledH2)`
  text-align: left;
  padding-top: 64px;
  margin-left: 85px;
`;

type SectionErrorComponentProps = {
  title: string;
  message: string;
};

function SectionErrorComponent({ title, message }: SectionErrorComponentProps) {
  return (
    <ErrorSectionContainer>
      <SectionErrorHeader>{title}</SectionErrorHeader>
      <SectionErrorWrapper>
        <ErrorImageComponent />
        <ErrorMessageComponent message={message} />
      </SectionErrorWrapper>
    </ErrorSectionContainer>
  );
}

export default SectionErrorComponent;
