import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Header } from "../../project/ProjectCard";
import { MergedProjectResponse } from "../map-setup-context/InitialMapValues";
import { useMapContext } from "../map-setup-context/MapContext";
import useTruncateText from "../../common/useTruncateText";
import {
  FlexColumn,
  FlexRow,
  Button,
  ATag,
  StyledH5,
  StyledBodyText,
} from "../../../styles";
import { SDGMapper } from "../../project/utils";
import getProjectFillColour from "../map-layers/utils";
import PopupImpact from "./PopupImpact";

const Container = styled(FlexColumn)`
  justify-content: center;
  width: 300px;
  right: 25%;
  top: 28%;
  background-color: #ffffff;
  box-shadow: -5px 12px 16px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  position: relative;
  z-index: 8;
  @media (max-width: 450px) {
    width: 300px;
    left: -90%;
    top: 50%;
  }
  @media (min-width: 451px) and (max-width: 767px) {
    width: 197px;
    right: 50%;
    top: 40%;
  }
  @media (min-width: 768px) and (max-width: 1198px) {
    width: 300px;
    right: 45%;
  }
  @media (min-width: 1199px) {
    left: -27%;
  }
`;

const ClickableContainer = styled(ATag)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 100%;
`;

const ImageHeader = styled(Header)`
  width: 100%;
  height: 215px;
  padding: 0;
  @media (min-width: 451px) and (max-width: 767px) {
    height: 140px;
  }
`;

const ProjectName = styled(StyledH5)`
  font-weight: 900;
  word-break: break-word;
  margin: 10px 12px 3px 12px;
`;

const NonClickableContainer = styled(FlexColumn)`
  justify-content: space-around;
  width: 100%;
  margin-top: 4px;
`;

const ImpactContainer = styled(FlexColumn)`
  align-items: start;
  padding: 5px 14px 0px 14px;
`;
const SDGContainer = styled(FlexColumn)`
  align-items: start;
  padding: 12px 14px 6px 18px;
`;

const SDGIcon = styled.img`
  margin-right: 8px;
  width: 30px;
  height: 30px;
`;

const SDGIconMore = styled(FlexRow)`
  width: 40px;
  height: 30px;
  background-color: #f7f7f7;
  align-items: center;
  justify-content: center;
`;

const ButtonContainer = styled(FlexRow)`
  justify-content: end;
  align-items: center;
  width: 97%;
  overflow: hidden;
  white-space: normal;
`;

const LearnMoreButton = styled(Button)`
  border: 1px solid #000;
  width: 100px;
  height: 28px;
  font-size: 0.75rem;
  box-shadow: none;
  margin: 0px 2px 6px 0px;
  &:hover {
    font-weight: 300;
    color: #ffffff;
  }
  @media (min-width: 451px) and (max-width: 800px) {
    height: 27px;
    font-size: 0.7rem;
    width: 95px;
  }
`;

const CloseButton = styled(Button)`
  width: 70px;
  height: 32px;
  font-size: 0.8rem;
  box-shadow: none;

  margin: 0px 5px 4px 0px;
  &:hover {
    background-color: #ffffff;
    color: #40e4ab;
    font-weight: 600;
  }
`;

type StyleProps = { backgroundColour: string };

const PopupBadge = styled.div<StyleProps>`
  flex-shrink: 0;
  position: absolute;
  top: 0;
  right: 1rem;
  background-color: ${(props) => props.backgroundColour};
  padding: 0.5rem 0.6rem;
  text-align: center;
  border-radius: 0 0 6px 6px;
  line-height: 1rem;
  font-size: 0.9rem;
  box-shadow: 4px 4px 15px rgba(26, 35, 126, 0.2);
`;

function PopupComponent({ project }: { project: MergedProjectResponse }) {
  const { setShowPopup, isMobile } = useMapContext();

  const text = project.name;
  const maxLength = 70;
  const isTooLong = text.length > maxLength;
  const truncatedProjectName = useTruncateText(text, maxLength, isTooLong);

  const sortedSdg = project.SDG?.sort((a, b) => a - b);
  const [sdgList, setSdgList] = useState<{
    visible: Array<number> | undefined;
    nonVisibleCount: number | null;
  }>({ visible: [], nonVisibleCount: null });
  const numberOfSDGIconsInLine = isMobile ? 4 : 6;
  const sliceAt = isMobile ? 3 : 6;
  useEffect(() => {
    if (sortedSdg && sortedSdg.length > numberOfSDGIconsInLine) {
      setSdgList({
        visible: sortedSdg.slice(0, sliceAt),
        nonVisibleCount: sortedSdg.length - 6,
      });
    } else {
      setSdgList({ visible: sortedSdg, nonVisibleCount: null });
    }
  }, [numberOfSDGIconsInLine, sliceAt, sortedSdg]);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const isNotFound = project.images.default.includes("not-found");
  const imageTestId = isNotFound
    ? `${project.images.default}-image-not-found`
    : `${project.images.default}-image`;

  const projectType = project.interventions[0]?.ecosystem[0] ?? "Forest";
  const backgroundColour = getProjectFillColour(projectType) || "#40E4AB";

  return (
    <Container data-testid="popup">
      <PopupBadge
        data-testid={`${projectType}-badge`}
        backgroundColour={backgroundColour}
      >
        <p>{projectType}</p>
        <p>Project</p>
      </PopupBadge>
      <ClickableContainer
        className="clickable-area-container"
        href={`https://earthly.org/${project.websiteUrl}`}
        target="_blank"
        rel="noreferrer"
      >
        <ImageHeader
          image={project.images.default}
          isNotFound={project.images.default.includes("not-found")}
          data-testid={imageTestId}
        />
        <ProjectName>{truncatedProjectName}</ProjectName>
      </ClickableContainer>
      <NonClickableContainer>
        <ImpactContainer>
          {project.mainImpact && <PopupImpact project={project} />}
        </ImpactContainer>
        <SDGContainer>
          <FlexRow>
            {sdgList.visible &&
              sdgList.visible.map((singleSdg) => (
                <SDGIcon
                  key={singleSdg}
                  src={SDGMapper[singleSdg]}
                  alt={`sdg-${singleSdg}`}
                  data-testid="sdg-icons-popup"
                />
              ))}
            {sdgList.nonVisibleCount && (
              <SDGIconMore data-testid="more-sdg">
                <StyledBodyText>{`+${sdgList.nonVisibleCount}`}</StyledBodyText>
              </SDGIconMore>
            )}
          </FlexRow>
        </SDGContainer>
        <ButtonContainer>
          <CloseButton
            onClick={handleClosePopup}
            data-testid="close-popup-button"
          >
            Close
          </CloseButton>
          <LearnMoreButton data-testid="learn-more-button">
            <ATag
              href={`https://earthly.org/${project.websiteUrl}`}
              target="_blank"
              rel="noreferrer"
            >
              Learn more
            </ATag>
          </LearnMoreButton>
        </ButtonContainer>
      </NonClickableContainer>
    </Container>
  );
}

export default PopupComponent;
