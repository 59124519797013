import axios from "axios";

const axiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_BASE_URL,
  timeout: 3000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "none",
  },
});
export default axiosInstance;
