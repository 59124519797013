import React from "react";
import styled from "styled-components";
import { FlexColumn, StyledH2 } from "../../styles";
import TransactionLedgerTable from "./TransactionLedgerTable";
import ErrorComponent from "../common/ErrorComponent";
import { TransactionsResponse } from "./types";

const TransactionsHeader = styled(StyledH2)`
  @media (max-width: 428px) {
    padding-top: 24px;
    font-size: 24px;
  }

  @media (min-width: 429px) {
    text-align: left;
    padding-top: 32px;
    padding-bottom: 32px;
    margin-left: 85px;
  }
`;

const StyledWrapper = styled(FlexColumn)`
  height: auto;
  padding-left: 8px;
  margin-top: 32px;
  margin-bottom: 32px;
  padding-right: 8px;
  background-color: #f7f7f7;
  border-radius: 16px;
`;

type TransactionLedgerProps = {
  transactionLedgerList: TransactionsResponse;
  hasError: boolean;
  isLedgerVisible: boolean;
};

function TransactionLedger({
  transactionLedgerList,
  hasError,
  isLedgerVisible,
}: TransactionLedgerProps) {
  if (!isLedgerVisible) return null;
  return (
    <StyledWrapper>
      <TransactionsHeader data-testid="transaction-test">
        Transaction History
      </TransactionsHeader>
      {hasError ? (
        <ErrorComponent message="We are having trouble showing your transaction history. If the problem persist, please get in touch with Earthly." />
      ) : (
        <TransactionLedgerTable data={transactionLedgerList} />
      )}
    </StyledWrapper>
  );
}

export default TransactionLedger;
