import React from "react";
import styled from "styled-components";
import { FlexColumn, StyledH2 } from "./styles";
import notFound from "./assets/not-found.png";

const Container = styled(FlexColumn)`
  min-height: 100vh;
  justify-content: center;
  align-items: center;
`;

const Description = styled(StyledH2)`
  padding-top: 32px;
  padding-left: 16px;
  padding-right: 16px;
  text-align: center;
  max-width: 1200px;

  @media (min-width: 429px) and (max-width: 768px) {
    font-size: 1.75rem;
    max-width: 700px;
  }

  @media (max-width: 428px) {
    font-size: 1.25rem;
    max-width: 390px;
  }
`;

const Image = styled.img`
  max-width: 30%;

  @media (max-width: 428px) {
    max-width: 50%;
  }

  @media (min-width: 429px) and (max-width: 768px) {
    max-width: 40%;
  }
`;

function ErrorPage({ message = "An error occurred" }: { message: string }) {
  return (
    <Container>
      <Image id="not-found-image" alt="page not found" src={notFound} />
      <Description>{message}</Description>
    </Container>
  );
}
export default ErrorPage;
