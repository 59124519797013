import { TransactionsResponse } from "../components/transaction-ledger/types";
import axiosInstance from "./axiosInstance";

const getTransactions = async (clientId: string) => {
  return axiosInstance.get<TransactionsResponse>(
    `/dashboard/v3/${clientId}/investments`,
  );
};

export default getTransactions;
