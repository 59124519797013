import { format } from "date-fns";
import React from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import styled from "styled-components";
import { FlexColumn } from "../../styles";

const customStyles = {
  headRow: {
    style: {
      display: "flex",
      alignItems: "center",
    },
  },
  headCells: {
    style: {
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: "1.1rem",
      fontStyle: "normal",
      letterSpacing: "-0.07em",
      color: "#201f1f",
    },
  },
  rows: {
    style: {
      fontFamily: "Inter",
      fontWeight: 400,
      fontSize: "14px",
      letterSpacing: "-0.07em",
      color: "rgba(90, 90, 90, 0.8)",
      borderBottom: "1px solid #E3E9F0",
    },
  },
};

type Props = {
  data: Array<{
    projectName: string;
    createdAt: number;
    unit: string;
    totalInvested: number;
  }>;
};

const StyledWrapper = styled(FlexColumn)`
  padding-left: 96px;
  margin-bottom: 32px;
  padding-right: 96px;
  border-radius: 16px;
  justify-content: center;
`;

const formatInvestment = (value: number): string => {
  if (value < 0.0001) {
    return "";
  }
  return value.toFixed(4).replace(/\.?0+$/, "");
};

function TransactionLedgerTable({ data }: Props) {
  const sortedData = data.sort((a, b) => b.createdAt - a.createdAt);

  const columns: TableColumn<{
    projectName: string;
    createdAt: number;
    unit: string;
    totalInvested: number;
  }>[] = [
    {
      name: "Project",
      selector: (row) => row.projectName,
      width: "55%",
    },
    {
      name: "Date",
      selector: (row) => row.createdAt,
      sortable: true,
      cell: (row) => format(new Date(row.createdAt), "MMM d, yyyy"),
      width: "20%",
    },
    {
      name: "Investment",
      selector: (row) => {
        const formattedInvestment = formatInvestment(row.totalInvested);
        return formattedInvestment ? `${formattedInvestment} ${row.unit}` : "";
      },
      width: "25%",
    },
  ];

  return (
    <StyledWrapper>
      <DataTable
        columns={columns}
        data={sortedData}
        pagination={sortedData.length > 10}
        paginationPerPage={10}
        customStyles={customStyles}
      />
    </StyledWrapper>
  );
}

export default TransactionLedgerTable;
