import React from "react";
import styled from "styled-components";
import { FlexColumn } from "../../../styles";
import { MergedProjectResponse } from "../map-setup-context/InitialMapValues";
import { useMapContext } from "../map-setup-context/MapContext";
import { getDifferentiatedProjectCoordinatePoint } from "../map-layers/utils";

const DropdownItem = styled(FlexColumn)`
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  background-color: white;
  word-break: break-word;
  border: grey solid 2px;
  box-shadow: -5px 12px 16px rgba(0, 0, 0, 0.15);
  padding: 8px 16px;
  width: 200px;
  z-index: 10;
  &:hover {
    background-color: #40e4ab;
    color: #ffffff;
    font-weight: 600;
    img {
      filter: invert(1);
    }
  }
`;

function FlyToButton({ project }: { project: MergedProjectResponse }) {
  const {
    mapRef,
    setShowDropdownMenu,
    setShowPopup,
    setPopupInfo,
    setProjectHighlight,
  } = useMapContext();

  const handleButtonClick = () => {
    if (mapRef.current) {
      const view = mapRef.current.getView();
      setShowPopup(false);
      setPopupInfo(project);
      if (project.unitData) {
        view.setMaxZoom(19);
        view.setZoom(19);
        setProjectHighlight(project);
      } else {
        view.setMaxZoom(13);
        view.setZoom(13);
      }
      const targetCenter = getDifferentiatedProjectCoordinatePoint(project);
      view.setCenter(targetCenter);
      setShowDropdownMenu(false);

      setTimeout(() => {
        setShowPopup(true);
      }, 500);
    }
  };

  return (
    <DropdownItem onClick={handleButtonClick}>{project.name}</DropdownItem>
  );
}

export default FlyToButton;
