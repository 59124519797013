const mockImpact = {
  total: {
    mainImpact: [
      {
        unit: "m3 Kelp",
        value: 639475,
      },
      {
        unit: "Tree(s)",
        value: 1000,
      },
      {
        unit: "m2",
        value: 1,
      },
      {
        unit: "Tonnes CO2",
        value: 100.1219048398,
      },
      {
        unit: "Biodiversity",
        value: 10012190,
      },
    ],
    coBenefits: [
      {
        unit: "Hours of Education",
        value: 7,
      },
      {
        unit: "Hours of Employment",
        value: 29.75,
      },
    ],
  },
  perProject: [
    {
      projectId: "64de08434d2c7a00463cfa2k",
      mainImpact: [
        {
          unit: "m3 Kelp",
          value: 639475,
        },
        {
          unit: "Tree(s)",
          value: 1000,
        },
      ],
      coBenefits: [
        {
          unit: "Hours of Education",
          value: 7,
        },
        {
          unit: "Hours of Employment",
          value: 29.75,
        },
      ],
    },
    {
      projectId: "64de0855f189da00ac141992",
      mainImpact: [
        {
          unit: "m3 Kelp",
          value: 475,
        },
        {
          unit: "Tree(s)",
          value: 10,
        },
      ],
      coBenefits: [
        {
          unit: "Hours of Education",
          value: 1,
        },
        {
          unit: "Hours of Employment",
          value: 8.75,
        },
      ],
    },
    {
      projectId: "64de08434d2c7a00463cfa2c",
      mainImpact: [
        {
          unit: "m3 Kelp",
          value: 475,
        },
        {
          unit: "Tree(s)",
          value: 10,
        },
      ],
      coBenefits: [
        {
          unit: "Hours of Education",
          value: 1,
        },
        {
          unit: "Hours of Employment",
          value: 8.75,
        },
      ],
    },
    {
      projectId: "64de08434d2c7a00463cfa2a",
      mainImpact: [
        {
          unit: "m3 Kelp",
          value: 475,
        },
        {
          unit: "Tree(s)",
          value: 10,
        },
      ],
      coBenefits: [
        {
          unit: "Hours of Education",
          value: 1,
        },
        {
          unit: "Hours of Employment",
          value: 8.75,
        },
      ],
    },
    {
      projectId: "64650c6bdd15c50018d3848e",
      mainImpact: [
        {
          unit: "m3 Kelp",
          value: 475,
        },
        {
          unit: "Tree(s)",
          value: 10,
        },
      ],
      coBenefits: [
        {
          unit: "Hours of Education",
          value: 1,
        },
        {
          unit: "Hours of Employment",
          value: 8.75,
        },
      ],
    },
    {
      projectId: "646bcb4872411e0056166399",
      mainImpact: [
        {
          unit: "m3 Kelp",
          value: 475,
        },
        {
          unit: "Tree(s)",
          value: 10,
        },
      ],
      coBenefits: [
        {
          unit: "Hours of Education",
          value: 1,
        },
        {
          unit: "Hours of Employment",
          value: 8.75,
        },
      ],
    },
    {
      projectId: "646bc81aab3c9d0066abf668",
      mainImpact: [
        {
          unit: "m3 Kelp",
          value: 475,
        },
        {
          unit: "Tree(s)",
          value: 10,
        },
      ],
      coBenefits: [
        {
          unit: "Hours of Education",
          value: 1,
        },
        {
          unit: "Hours of Employment",
          value: 8.75,
        },
      ],
    },
    {
      projectId: "646bc8faab3c9d0066abf669",
      mainImpact: [
        {
          unit: "m3 Kelp",
          value: 475,
        },
        {
          unit: "Tree(s)",
          value: 10,
        },
      ],
      coBenefits: [
        {
          unit: "Hours of Education",
          value: 1,
        },
        {
          unit: "Hours of Employment",
          value: 8.75,
        },
      ],
    },
    {
      projectId: "646bca2d72411e0056166397",
      mainImpact: [
        {
          unit: "m3 Kelp",
          value: 475,
        },
        {
          unit: "Tree(s)",
          value: 10,
        },
      ],
      coBenefits: [
        {
          unit: "Hours of Education",
          value: 1,
        },
        {
          unit: "Hours of Employment",
          value: 8.75,
        },
      ],
    },
    {
      projectId: "646515bcdd15c50018d38494",
      mainImpact: [
        {
          unit: "m3 Kelp",
          value: 475,
        },
        {
          unit: "Tree(s)",
          value: 10,
        },
      ],
      coBenefits: [
        {
          unit: "Hours of Education",
          value: 1,
        },
        {
          unit: "Hours of Employment",
          value: 8.75,
        },
      ],
    },
    {
      projectId: "64de0865f189da00ac141993",
      mainImpact: [
        {
          unit: "m3 Kelp",
          value: 475,
        },
        {
          unit: "Tree(s)",
          value: 10,
        },
      ],
      coBenefits: [
        {
          unit: "Hours of Education",
          value: 1,
        },
        {
          unit: "Hours of Employment",
          value: 8.75,
        },
      ],
    },
    {
      projectId: "64a6c050886dad00420d19db",
      mainImpact: [
        {
          unit: "m3 Kelp",
          value: 475,
        },
        {
          unit: "Tree(s)",
          value: 10,
        },
      ],
      coBenefits: [
        {
          unit: "Hours of Education",
          value: 1,
        },
        {
          unit: "Hours of Employment",
          value: 8.75,
        },
      ],
    },
    {
      projectId: "64de08784d2c7a00463cfa2d",
      mainImpact: [
        {
          unit: "m3 Kelp",
          value: 475,
        },
        {
          unit: "Tree(s)",
          value: 10,
        },
      ],
      coBenefits: [
        {
          unit: "Hours of Education",
          value: 1,
        },
        {
          unit: "Hours of Employment",
          value: 8.75,
        },
      ],
    },
    {
      projectId: "64de08854d2c7a00463cfa2e",
      mainImpact: [
        {
          unit: "m3 Kelp",
          value: 475,
        },
        {
          unit: "Tree(s)",
          value: 10,
        },
      ],
      coBenefits: [
        {
          unit: "Hours of Education",
          value: 1,
        },
        {
          unit: "Hours of Employment",
          value: 8.75,
        },
      ],
    },
    {
      projectId: "64ef7a2b53303e0018d57861",
      mainImpact: [
        {
          unit: "m3 Kelp",
          value: 475,
        },
        {
          unit: "Tree(s)",
          value: 10,
        },
      ],
      coBenefits: [
        {
          unit: "Hours of Education",
          value: 1,
        },
        {
          unit: "Hours of Employment",
          value: 8.75,
        },
      ],
    },
    {
      projectId: "64ef7a2b53303e0018d57861",
      mainImpact: [
        {
          unit: "m3 Kelp",
          value: 475,
        },
        {
          unit: "Tree(s)",
          value: 10,
        },
      ],
      coBenefits: [
        {
          unit: "Hours of Education",
          value: 1,
        },
        {
          unit: "Hours of Employment",
          value: 8.75,
        },
      ],
    },
    {
      projectId: "6464a0b71fb8a100753ffa97",
      mainImpact: [
        {
          unit: "m3 Kelp",
          value: 475,
        },
        {
          unit: "Tree(s)",
          value: 10,
        },
      ],
      coBenefits: [
        {
          unit: "Hours of Education",
          value: 1,
        },
        {
          unit: "Hours of Employment",
          value: 8.75,
        },
      ],
    },
    {
      projectId: "64650b5a92f304001139ec1c",
      mainImpact: [
        {
          unit: "m3 Kelp",
          value: 475,
        },
        {
          unit: "Tree(s)",
          value: 10,
        },
      ],
      coBenefits: [
        {
          unit: "Hours of Education",
          value: 1,
        },
        {
          unit: "Hours of Employment",
          value: 8.75,
        },
      ],
    },
    {
      projectId: "6438298b4630d90019a44345",
      mainImpact: [
        {
          unit: "m3 Kelp",
          value: 475,
        },
        {
          unit: "Tree(s)",
          value: 10,
        },
      ],
      coBenefits: [
        {
          unit: "Hours of Education",
          value: 1,
        },
        {
          unit: "Hours of Employment",
          value: 8.75,
        },
      ],
    },
    {
      projectId: "64650eb7dd15c50018d3848f",
      mainImpact: [
        {
          unit: "m3 Kelp",
          value: 475,
        },
        {
          unit: "Tree(s)",
          value: 10,
        },
      ],
      coBenefits: [
        {
          unit: "Hours of Education",
          value: 1,
        },
        {
          unit: "Hours of Employment",
          value: 8.75,
        },
      ],
    },
    {
      projectId: "66696e6e8ac35300117c5aa9",
      mainImpact: [
        {
          unit: "m3 Kelp",
          value: 475,
        },
        {
          unit: "Tree(s)",
          value: 10,
        },
      ],
      coBenefits: [
        {
          unit: "Hours of Education",
          value: 1,
        },
        {
          unit: "Hours of Employment",
          value: 8.75,
        },
      ],
    },
    {
      projectId: "6464b6ec1fb8a100753ffa9b",
      mainImpact: [
        {
          unit: "m3 Kelp",
          value: 475,
        },
        {
          unit: "Tree(s)",
          value: 10,
        },
      ],
      coBenefits: [
        {
          unit: "Hours of Education",
          value: 1,
        },
        {
          unit: "Hours of Employment",
          value: 8.75,
        },
      ],
    },
    {
      projectId: "646bcac372411e0056166398",
      mainImpact: [
        {
          unit: "m3 Kelp",
          value: 475,
        },
        {
          unit: "Tree(s)",
          value: 10,
        },
      ],
      coBenefits: [
        {
          unit: "Hours of Education",
          value: 1,
        },
        {
          unit: "Hours of Employment",
          value: 8.75,
        },
      ],
    },
    {
      projectId: "666961a17330430018ee83de",
      mainImpact: [
        {
          unit: "m3 Kelp",
          value: 475,
        },
        {
          unit: "Tree(s)",
          value: 10,
        },
      ],
      coBenefits: [
        {
          unit: "Hours of Education",
          value: 1,
        },
        {
          unit: "Hours of Employment",
          value: 8.75,
        },
      ],
    },
    {
      projectId: "6464b4dca3776e0065d02e30",
      mainImpact: [
        {
          unit: "m3 Kelp",
          value: 475,
        },
        {
          unit: "Tree(s)",
          value: 10,
        },
      ],
      coBenefits: [
        {
          unit: "Hours of Education",
          value: 1,
        },
        {
          unit: "Hours of Employment",
          value: 8.75,
        },
      ],
    },
    {
      projectId: "6464aa0fa3776e0065d02e2f",
      mainImpact: [
        {
          unit: "m3 Kelp",
          value: 475,
        },
        {
          unit: "Tree(s)",
          value: 10,
        },
      ],
      coBenefits: [
        {
          unit: "Hours of Education",
          value: 1,
        },
        {
          unit: "Hours of Employment",
          value: 8.75,
        },
      ],
    },
    {
      projectId: "64de08a64d2c7a00463cfa30",
      mainImpact: [
        {
          unit: "m3 Kelp",
          value: 475,
        },
        {
          unit: "Tree(s)",
          value: 10,
        },
      ],
      coBenefits: [
        {
          unit: "Hours of Education",
          value: 1,
        },
        {
          unit: "Hours of Employment",
          value: 8.75,
        },
      ],
    },
    {
      projectId: "64651378dd15c50018d38493",
      mainImpact: [
        {
          unit: "m2",
          value: 475,
        },
        {
          unit: "Tonnes Co2",
          value: 10,
        },
      ],
      coBenefits: [
        {
          unit: "Hours of Education",
          value: 1,
        },
        {
          unit: "Hours of Employment",
          value: 8.75,
        },
      ],
    },
    {
      projectId: "64649cfea3776e0065d02e2c",
      mainImpact: [
        {
          unit: "m3 Kelp",
          value: 475,
        },
        {
          unit: "m2",
          value: 10,
        },
      ],
      coBenefits: [
        {
          unit: "Hours of Education",
          value: 1,
        },
        {
          unit: "Hours of Employment",
          value: 8.75,
        },
      ],
    },
    {
      projectId: "65afa75d40b5cb00125ddc7d",
      mainImpact: [
        {
          unit: "m3 Kelp",
          value: 475,
        },
        {
          unit: "Tree(s)",
          value: 10,
        },
      ],
      coBenefits: [
        {
          unit: "Hours of Education",
          value: 1,
        },
        {
          unit: "Hours of Employment",
          value: 8.75,
        },
      ],
    },
  ],
};

export default mockImpact;
