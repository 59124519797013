import noPoverty from "../../assets/sdg/media_admindata_images_1612372841503v91-qNoDb.png";
import zeroHunger from "../../assets/sdg/media_admindata_images_16123728419206r7YuKNOAG.png";
import goodHealth from "../../assets/sdg/media_admindata_images_1612372842077FBvAgvZ9W.png";
import qualityEducation from "../../assets/sdg/media_admindata_images_1612372842243nmVsehAD61.png";
import genderEquality from "../../assets/sdg/media_admindata_images_1612372842395pDDnP-atBb.png";
import cleanWater from "../../assets/sdg/media_admindata_images_16123728425706yf5naO73j.png";
import affordableEnergy from "../../assets/sdg/media_admindata_images_1612372842724rxwNrIJxai.png";
import economicGrowth from "../../assets/sdg/media_admindata_images_1612372842898gg1ajpemUi.png";
import industryInnovationAndInfrastructure from "../../assets/sdg/media_admindata_images_1612372843081KR0t8D494.png";
import reducedInequalities from "../../assets/sdg/media_admindata_images_1612372843246sTXpImy3Rw.png";
import sustainableCities from "../../assets/sdg/media_admindata_images_1612372843399AakXs3aeOR.png";
import responsibleConsumption from "../../assets/sdg/media_admindata_images_1612372843564FcuyuDw91h.png";
import climateAction from "../../assets/sdg/media_admindata_images_1612372843743EDUgLi0e9E.png";
import lifeBelowWater from "../../assets/sdg/media_admindata_images_1612372843894aud4tFIiwP.png";
import lifeOnLand from "../../assets/sdg/media_admindata_images_1612372844051A3LDx9mzWF.png";
import peaceJustice from "../../assets/sdg/media_admindata_images_1612372844200SAz_AM7O-.png";
import partnerships from "../../assets/sdg/media_admindata_images_1612372844389pfJR6Jvm68.png";

export const SDGMapper: { [key: number]: string } = {
  1: noPoverty,
  2: zeroHunger,
  3: goodHealth,
  4: qualityEducation,
  5: genderEquality,
  6: cleanWater,
  7: affordableEnergy,
  8: economicGrowth,
  9: industryInnovationAndInfrastructure,
  10: reducedInequalities,
  11: sustainableCities,
  12: responsibleConsumption,
  13: climateAction,
  14: lifeBelowWater,
  15: lifeOnLand,
  16: peaceJustice,
  17: partnerships,
};

export default {};
