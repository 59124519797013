import React from "react";
import styled from "styled-components";
import { MergedProjectResponse } from "../map-setup-context/InitialMapValues";
import { FlexColumn, StyledBodyTextSmall } from "../../../styles";
import treesIcon from "../../../assets/icons/treesIcon.png";
import m3KelpIcon from "../../../assets/icons/m3KelpIcon.png";
import m2Icon from "../../../assets/icons/m2Icon.png";
import tonnesCo2Icon from "../../../assets/icons/tonnesCo2Icon.png";
import biodiversityUnitIcon from "../../../assets/icons/biodiversityIcon.png";
import biodiversityAreaIcon from "../../../assets/icons/biodiversityAreaIcon.png";
import educationIcon from "../../../assets/icons/educationIcon.png";
import employmentIcon from "../../../assets/icons/employmentIcon.png";
import defaultIcon from "../../../assets/icons/globe-2.png";
import { formatVolume } from "../../co-benefits";

export type Impact =
  | "m2"
  | "m3 Kelp"
  | "Tonnes CO2"
  | "Tree(s)"
  | "Biodiversity"
  | "Hours of Employment"
  | "Hours of Education";

const getIconForImpact = (impact: string): string => {
  const iconMap: { [key: string]: string } = {
    "Hours of Education": educationIcon,
    "Hours of Employment": employmentIcon,
    m2: m2Icon,
    "m3 Kelp": m3KelpIcon,
    "Tonnes CO2": tonnesCo2Icon,
    "Tree(s)": treesIcon,
  };
  return iconMap[impact] || defaultIcon;
};

const ImpactRow = styled(StyledBodyTextSmall)`
  display: flex;
  flex-direction: row;
  line-height: 1.6;
  font-size: 12px;
`;

const Icon = styled.img`
  margin: 0px 4px 0px 2px;
  width: 18px;
  height: 18px;
`;

export type ImpactPopupProps = {
  project: MergedProjectResponse;
};

function PopupImpact({ project }: ImpactPopupProps) {
  return (
    <FlexColumn>
      {project.unitData ? (
        <>
          <ImpactRow data-testid="biodiversity-unit-row">
            <Icon src={biodiversityUnitIcon} alt="biodiversity-unit-icon" />
            Voluntary Biodiversity Units: {project.unitData.unitCount}
          </ImpactRow>
          <ImpactRow data-testid="biodiversity-area-row">
            <Icon src={biodiversityAreaIcon} alt="biodiversity-area-icon" />
            Total Area: {project.unitData.clientUnitTotalArea} m²
          </ImpactRow>
        </>
      ) : (
        <>
          {project.mainImpact
            .filter((impact) => impact.value !== 0)
            .map((impact) => (
              <ImpactRow
                key={impact.unit}
                data-testid={`${impact.unit}-impact-unit-row`}
              >
                <Icon
                  src={getIconForImpact(`${impact.unit}`)}
                  alt={`${impact.unit}-unitIcon`}
                />
                {impact.unit}: {impact.value}
              </ImpactRow>
            ))}

          {project.coBenefits.map((coBenefit) => {
            const { hours, minutes } = formatVolume(
              coBenefit.value,
              coBenefit.unit,
            );
            return (
              <ImpactRow
                key={coBenefit.unit}
                data-testid={`${coBenefit.unit}-coBenefit-unit-row`}
              >
                <Icon
                  src={getIconForImpact(`${coBenefit.unit}`)}
                  alt={`${coBenefit.unit}-unitIcon`}
                />
                {coBenefit.unit}: {hours}h {minutes}m{" "}
              </ImpactRow>
            );
          })}
        </>
      )}
    </FlexColumn>
  );
}
export default PopupImpact;
