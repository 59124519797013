import styled from "styled-components";

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
`;

export const StyledH0 = styled.h1`
  font-size: 6rem;
  letter-spacing: 0.02em;
  line-height: 1;
  font-weight: 500;
  color: #201f1f;
  font-family: "Druk", serif;
`;

export const StyledH1 = styled.h1`
  font-family: "Druk", serif;
  font-size: 4rem;
  line-height: 1;
  letter-spacing: -0.02em;
  font-weight: 500;
  color: #201f1f;
`;

export const StyledH2 = styled.h2`
  font-family: "Inter", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 2.2rem;
  line-height: 1;
  letter-spacing: -0.02em;
  color: #201f1f;
`;

export const StyledH4 = styled.h4`
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1;
  font-style: normal;
  letter-spacing: -0.07em;
  color: #201f1f;
`;

export const StyledH5 = styled.h4`
  font-family: "Inter", sans-serif;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1;
  font-style: normal;
  letter-spacing: -0.07em;
  color: #201f1f;
`;

export const StyledBodyText = styled.p`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: -0.07em;
  color: #201f1f;
`;

export const StyledBodyTextSmall = styled.p`
  font-family: "Inter", sans-serif;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.07em;
  color: #201f1f;
`;

export const StyledLabelText = styled.p`
  align-self: start;
  font-family: Inter, serif;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: -0.07em;
  color: #201f1f;
`;

export const Digits = styled.h1`
  font-family: "Druk", serif;
  font-style: normal;
  font-weight: 500;
  font-size: 4.4rem;
  letter-spacing: -0.02em;
  line-height: 1;
  color: #201f1f;
`;

export const ATag = styled.a`
  color: black;
  text-decoration: none;
  &:hover {
    font-weight: 300;
    color: #ffffff;
  }
`;

export const Button = styled(FlexRow)`
  cursor: pointer;
  align-items: center;
  justify-content: space-around;
  background-color: #ffffff;
  border-radius: 18px;
  box-shadow: -5px 12px 16px rgba(0, 0, 0, 0.15);
  padding: 8px 16px;
  width: 200px;
  z-index: 10;
  color: black;
  &:hover {
    background-color: #40e4ab;
    color: #ffffff;
    font-weight: 600;
    img {
      filter: invert(1);
    }
  }
`;

export default {};
