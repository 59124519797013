import ReactDOMServer from "react-dom/server";
import { ReactElement } from "react";

interface ShapedLogoMakerProps {
  size: number;
  fillColour: string;
  logo: ReactElement | null;
}

export function generateShapedLogoSvg({
  size,
  fillColour,
  logo,
}: ShapedLogoMakerProps): string {
  const halfSize = size / 2;
  const logoWidth = 67;
  const logoHeight = 70;
  const translateX = -logoWidth / 2.2;
  const translateY = -logoHeight / 2.2;

  const logoString = logo ? ReactDOMServer.renderToString(logo) : "";

  const dodecahedronSvgString = `
      <svg
        viewBox="-${halfSize} -${halfSize} ${size} ${size}"
        xmlns="http://www.w3.org/2000/svg"
        width="${size}"
        height="${size}"
      >
        <path
          d="
            M 50 0 
            L 43.3 -25 
            L 25 -43.3 
            L 0 -50 
            L -25 -43.3 
            L -43.3 -25 
            L -50 0 
            L -43.3 25 
            L -24 47 
            L 0 78 
            L 24 47 
            L 43.3 25 
            Z
          "
          fill="${fillColour}"
        />
        <g
          transform="translate(${translateX}, ${translateY}) scale(${Math.min(
    size / 100,
    0.9,
  )})"
        >
          ${logoString}
        </g>
      </svg>
    `;

  return dodecahedronSvgString;
}

function useCreateShapedLogo({
  size,
  fillColour,
  logo,
}: ShapedLogoMakerProps): string {
  const svgString = generateShapedLogoSvg({ size, fillColour, logo });
  const svgBlob = new Blob([svgString], {
    type: "image/svg+xml;charset=utf-8",
  });
  const url = URL.createObjectURL(svgBlob);

  return url;
}

export default useCreateShapedLogo;
