/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createContext,
  useContext,
  useMemo,
  useEffect,
  useRef,
} from "react";
import { Pointer } from "ol/interaction";
import { Zoom } from "ol/control";
import {
  MapContexts,
  defaultContext,
  MergedProjectResponse,
  currentMap,
} from "./InitialMapValues";
import getMarkerLayer from "../map-layers/getMarkerLayer";
import getProjectHighlightLayer from "../map-layers/getHighlightLayer";
import useMapStates from "./useMapStates";
import { addOrRemoveControlButtons } from "./utils";

const MapContext = createContext<MapContexts>(defaultContext);

export function MapProvider({ children }: { children: React.ReactNode }) {
  const [projects, setProjects] = React.useState<MergedProjectResponse[]>([]);
  const {
    showMap,
    setShowMap,
    showDropdownMenu,
    setShowDropdownMenu,
    projectHighlight,
    setProjectHighlight,
    showPopup,
    setShowPopup,
    popupInfo,
    setPopupInfo,
    dataId,
    setDataId,
    clientId,
    isMobile,
  } = useMapStates(defaultContext);
  const mapRef = useRef(currentMap);

  useEffect(() => {
    if (mapRef.current) {
      if (isMobile) {
        addOrRemoveControlButtons(mapRef.current, Zoom, "remove");
      } else {
        addOrRemoveControlButtons(mapRef.current, Zoom, "add");
      }
      mapRef.current.updateSize();
      mapRef.current.render();
    }
  }, [clientId, isMobile]);

  useEffect(() => {
    if (mapRef.current && projects.length > 0) {
      const { vectorLayer, selectClick } = getMarkerLayer(
        projects,
        setPopupInfo,
        setShowPopup,
      );
      const hoverInteraction = new Pointer({
        handleMoveEvent: (event) => {
          const { pixel } = event;
          const hit = currentMap.hasFeatureAtPixel(pixel);
          const targetElement = currentMap.getTargetElement();
          targetElement.style.cursor = hit ? "pointer" : "default";
        },
      });
      mapRef.current.addLayer(vectorLayer);
      mapRef.current.addInteraction(hoverInteraction);
      mapRef.current.addInteraction(selectClick);
      const dataIdToSet = "markers";
      vectorLayer.setZIndex(2);
      setDataId(dataIdToSet);
      setShowMap(true);
    } else {
      setShowMap(false);
    }
  }, [
    projects,
    currentMap,
    mapRef,
    setPopupInfo,
    setShowPopup,
    setDataId,
    setShowMap,
  ]);

  const setHighlightLayerAndRemovePrevious = async (
    project: MergedProjectResponse,
  ) => {
    const existingLayer = mapRef.current
      .getLayers()
      .getArray()
      .find((layer) => layer.get("isHighlightLayer"));

    if (existingLayer) {
      mapRef.current.removeLayer(existingLayer);
    }
    const highlightLayer = await getProjectHighlightLayer(project);
    highlightLayer.set("isHighlightLayer", true);
    highlightLayer.setZIndex(1);
    mapRef.current.addLayer(highlightLayer);
  };

  useEffect(() => {
    if (projectHighlight) {
      setHighlightLayerAndRemovePrevious(projectHighlight);
    }
  }, [projectHighlight, setProjectHighlight]);

  const contextValue = useMemo(
    () => ({
      mapRef,
      projects,
      setProjects,
      showMap,
      showDropdownMenu,
      setShowDropdownMenu,
      projectHighlight,
      setProjectHighlight,
      showPopup,
      setShowPopup,
      popupInfo,
      setPopupInfo,
      isMobile,
      dataId,
    }),
    [
      projects,
      setProjects,
      showMap,
      showDropdownMenu,
      popupInfo,
      showPopup,
      isMobile,
      dataId,
      mapRef,
      setPopupInfo,
      setShowDropdownMenu,
      projectHighlight,
      setProjectHighlight,
      setShowPopup,
    ],
  );

  return (
    <MapContext.Provider value={contextValue}>{children}</MapContext.Provider>
  );
}

export default MapProvider;

export const useMapContext = () => {
  return useContext(MapContext);
};
