import { createBrowserRouter, RouterProvider } from "react-router-dom";
import React from "react";
import App from "./App";
import ErrorPage from "./ErrorPage";

const router = createBrowserRouter([
  {
    path: "/:clientId",
    element: <App />,
  },
  {
    path: "*",
    element: <ErrorPage message="Sorry, this page can’t be found." />,
  },
]);

function RouterWrapper() {
  return <RouterProvider router={router} />;
}

export default RouterWrapper;
