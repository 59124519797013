import React from "react";
import styled from "styled-components";
import { Tooltip } from "react-tooltip";
import {
  FlexColumn,
  StyledBodyText,
  FlexRow,
  StyledBodyTextSmall,
  Digits,
} from "../../styles";
import Icon from "../common/Icon";
import infoIcon from "../../assets/icons/info.png";

const Container = styled(FlexColumn)`
  height: 216px;
  width: 320px;
  border-radius: 16px;
  background: #ffffff;
  padding: 8px 8px;
  justify-content: space-between;
  margin: 8px;
`;

const CardHeader = styled(FlexRow)`
  align-content: start;
  height: 10%;
  min-height: 30px;
  justify-content: space-between;
`;

const CardBody = styled(FlexRow)`
  height: 90%;
`;

const InnerContainer = styled(FlexColumn)`
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
`;

const Volume = styled(Digits)`
  padding-top: 8px;
  font-size: 40px;
  text-align: center;
`;

const TagContainer = styled(FlexRow)`
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  border-radius: 18px;
`;

const Text = styled(StyledBodyTextSmall)`
  padding: 6px 16px;
`;

const Description = styled(StyledBodyText)`
  padding-bottom: 16px;
  color: #5a5a5a;
`;

const StyledInfoIcon = styled.img`
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const StyledTooltip = styled(Tooltip)`
  && {
    background-color: white;
    color: black;
    border-radius: 16px;
    font-size: 12px;
`;

type Props = {
  icon: string;
  unit: string;
  hours: number;
  minutes: number;
};

function Tag() {
  return (
    <TagContainer>
      <Text>Social</Text>
    </TagContainer>
  );
}

function CoBenefitCard({ unit, icon, hours, minutes }: Props) {
  return (
    <Container data-testid={`cobenefit-card-${unit}`}>
      <CardHeader>
        <Tag />
        <StyledInfoIcon
          src={infoIcon}
          alt="Icon"
          data-tooltip-id={`infoicon-${unit}`}
        />
      </CardHeader>
      <CardBody>
        <InnerContainer>
          <Icon icon={icon} description={unit} />
        </InnerContainer>
        <InnerContainer>
          <Volume>
            {hours} hour{hours !== 1 && "s"}
            {minutes !== null && minutes > 0 && (
              <>
                <br />
                {minutes} minute{minutes !== 1 && "s"}
              </>
            )}
          </Volume>
          <Description>{unit}</Description>
        </InnerContainer>
      </CardBody>
      <StyledTooltip
        id={`infoicon-${unit}`}
        place="top"
        content="Estimated using project documentation and latest reports."
        opacity={1}
        border="1px solid black"
      />
    </Container>
  );
}

export default CoBenefitCard;
