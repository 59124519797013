import { ReactElement } from "react";
import { fromLonLat } from "ol/proj";
import {
  Forest,
  Grassland,
  Peatland,
  Farmland,
  Marine,
  Coastal,
  Desert,
  Freshwater,
  BackupWorldIcon,
} from "./logos";
import { ProjectResponse } from "../map-setup-context/InitialMapValues";

const availableFillColours = {
  EARTHLY: "#40e4ab" as const,
  SAND: "#D9CA80" as const,
  SUN: "#E35E40" as const,
  SUN50: "#eba899" as const,
  CARBON: "#797979" as const,
  SEA: "#3062C0" as const,
  SEA50: "#91aad9" as const,
  SKY: "#76BEC6" as const,
  PLAIN: "#ffffff" as const,
} as const;

type FillColours =
  (typeof availableFillColours)[keyof typeof availableFillColours];

type ProjectStyles = {
  icon: ReactElement;
  colour: FillColours;
};

export const projectDetailsMapper: Record<string, ProjectStyles> = {
  Forest: { icon: Forest, colour: availableFillColours.EARTHLY },
  Grassland: { icon: Grassland, colour: availableFillColours.SUN50 },
  Peatland: { icon: Peatland, colour: availableFillColours.SUN },
  Farmland: { icon: Farmland, colour: availableFillColours.PLAIN },
  Marine: { icon: Marine, colour: availableFillColours.SEA },
  Coastal: { icon: Coastal, colour: availableFillColours.SKY },
  Desert: { icon: Desert, colour: availableFillColours.SAND },
  Freshwater: { icon: Freshwater, colour: availableFillColours.SEA50 },
  FallbackOption: {
    icon: BackupWorldIcon,
    colour: availableFillColours.EARTHLY,
  },
};

export function getProjectIcon(type: string): ReactElement | null {
  return (
    projectDetailsMapper[type]?.icon || projectDetailsMapper.FallbackOption.icon
  );
}

export function getProjectFillColour(type: string): string {
  return projectDetailsMapper[type]?.colour;
}

export function getCheckIfValidDegreeCoordinate(
  coord: number,
  type: "lat" | "lon",
) {
  if (typeof coord !== "number" || Number.isNaN(coord)) return false;
  if (type === "lat" && (coord < -90 || coord > 90)) return false;
  if (type === "lon" && (coord < -180 || coord > 180)) return false;
  return true;
}

export function getDifferentiatedProjectCoordinatePoint(
  project: ProjectResponse,
) {
  const flyToCoordinates = [-0.05813303, 51.519018];
  const nonBiodiversityCoords = project.centerCoordinates;
  const biodiversityCoords = project?.unitData?.geometry.features[0]?.geometry
    .coordinates[0][0][0] as number[] | undefined;

  let coordinatesToFlyTo: number[] = flyToCoordinates;

  if (biodiversityCoords) {
    coordinatesToFlyTo = biodiversityCoords;
  } else if (
    nonBiodiversityCoords &&
    getCheckIfValidDegreeCoordinate(nonBiodiversityCoords.lat, "lat") &&
    getCheckIfValidDegreeCoordinate(nonBiodiversityCoords.lon, "lon")
  ) {
    coordinatesToFlyTo = [nonBiodiversityCoords.lon, nonBiodiversityCoords.lat];
  } else {
    coordinatesToFlyTo = flyToCoordinates;
  }

  const coordinatePoint = fromLonLat(coordinatesToFlyTo);
  return coordinatePoint;
}

export default getProjectFillColour;
