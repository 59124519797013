import React from "react";
import styled from "styled-components";
import { FlexColumn } from "../../styles";
import ErrorImageComponent from "./ErrorImage";
import ErrorMessageComponent from "./ErrorMessage";

const ErrorContainer = styled(FlexColumn)`
  padding: 16px;
  margin-bottom: 32px;
  justify-content: center;
`;

type ErrorFallbackProps = {
  message: string;
};

function ErrorFallbackComponent({ message }: ErrorFallbackProps) {
  return (
    <ErrorContainer>
      <ErrorImageComponent />
      <ErrorMessageComponent message={message} />
    </ErrorContainer>
  );
}

export default ErrorFallbackComponent;
