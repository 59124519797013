import ScaleLine, { Units } from "ol/control/ScaleLine";
import Zoom from "ol/control/Zoom";
import Attribution from "ol/control/Attribution";
import { Map } from "ol";

type ControlClass = typeof ScaleLine | typeof Zoom | typeof Attribution;

export function addOrRemoveControlButtons(
  map: Map,
  ControlClass: ControlClass,
  action: "add" | "remove",
  options?: { units?: Units; className: string },
) {
  const controlsArray = map.getControls().getArray();

  const existingControls = controlsArray.filter(
    (control): control is InstanceType<ControlClass> =>
      control instanceof ControlClass,
  );

  if (action === "remove" && existingControls.length > 0) {
    existingControls.forEach((control) => {
      map.removeControl(control);
    });
    return;
  }

  if (action === "add" && existingControls.length === 0) {
    let newControl;
    if (ControlClass === ScaleLine) {
      newControl = new ScaleLine(options);
    } else {
      newControl = new ControlClass();
    }

    map.addControl(newControl);
  }
}

export default {};
