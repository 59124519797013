const mockinvestments = [
  {
    projectName: "Tropical Forest Protection, Keo Seima",
    createdAt: 1694695103899,
    unit: "Tonnes CO2",
    totalInvested: 345,
  },
  {
    projectName: "Mangrove Restoration Pakistan",
    createdAt: 1694695133605,
    unit: "Tonnes CO2",
    totalInvested: 86.1,
  },
  {
    projectName: "Kasigau Corridor REDD+ Project",
    createdAt: 1660197305538,
    unit: "Tonnes CO2",
    totalInvested: 195.01,
  },
  {
    projectName: "Culm Moor Woodland Reserve",
    createdAt: 1660206225418,
    unit: "Tree(s)",
    totalInvested: 100.000251,
  },
  {
    projectName: "Tree Planting- TIST Kenya",
    createdAt: 1710138243000,
    unit: "Tree(s)",
    totalInvested: 300,
  },
  {
    projectName: "Rimba Raya",
    createdAt: 1699683843000,
    unit: "Tonnes CO2",
    totalInvested: 456,
  },
  {
    projectName: "Mangrove Planting, Maroalika",
    createdAt: 1659335043000,
    unit: "Tree(s)",
    totalInvested: 1100,
  },
];

export default mockinvestments;
