import React from "react";
import styled from "styled-components";
import { FlexRow } from "../../styles";

const CenteredFlexRow = styled(FlexRow)`
  justify-content: center;
  width: 100%;
`;

const ErrorMessage = styled.p`
  font-size: 18px;
  color: #333;
`;

type ErrorMessageProps = {
  message: string;
};

function ErrorMessageComponent({ message }: ErrorMessageProps) {
  return (
    <CenteredFlexRow>
      <ErrorMessage>{message}</ErrorMessage>
    </CenteredFlexRow>
  );
}

export default ErrorMessageComponent;
