import React, { useEffect } from "react";
import styled from "styled-components";
import {
  FlexColumn,
  StyledLabelText,
  StyledH4,
  StyledBodyText,
  FlexRow,
  StyledBodyTextSmall,
  Digits,
} from "../../styles";
import Icon from "../common/Icon";
import { formatValueToShort, outcomeDataMapper } from "./utils";

const Container = styled(FlexColumn)<{ type: string }>`
  min-width: 320px;
  max-width: 361px;
  height: 216px;
  border-radius: 16px;
  background: ${(props) =>
    props.type === "large"
      ? "linear-gradient(184.52deg, #a9c1d5 -69.37%, #d9e8fb 96.33%)"
      : "#f7f7f7"};
  padding: 8px 8px 32px;
`;

const IconContainer = styled(FlexColumn)`
  width: 45%;
  height: 100%;
  justify-content: center;
  align-content: center;
`;

const CardHeader = styled(FlexRow)`
  align-content: start;
  height: 15%;
  min-height: 30px;
`;

const VerifiedEstimatedContainer = styled(FlexRow)`
  min-width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: space-between;
`;

const VerifiedContainer = styled(FlexColumn)`
  padding-left: 8px;
  border-left: 2px solid #14cd8d;
`;

const EstimatedContainer = styled(FlexColumn)`
  padding-left: 8px;
  border-left: 2px solid #05291d;
  align-self: end;
`;

const Volume = styled(Digits)`
  line-height: 0.8;
`;

const TopImpactTagContainer = styled(FlexRow)`
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 18px;
`;

const TopImpactText = styled(StyledBodyTextSmall)`
  padding: 6px 16px;
`;

const VolumeDetailed = styled(StyledBodyText)`
  color: #5a5a5a;
`;

const Description = styled(StyledBodyText)`
  padding-bottom: 16px;
`;

const CardContent = styled(FlexRow)`
  height: 85%;
`;

const DataContainer = styled(FlexColumn)`
  width: 55%;
  height: 100%;
  align-items: center;
  justify-content: end;
  padding-bottom: 8px;
`;

function TopImpactTag() {
  return (
    <TopImpactTagContainer>
      <TopImpactText>Top Impact</TopImpactText>
    </TopImpactTagContainer>
  );
}

type Props = {
  volume: number;
  unit: string;
  verified: number;
  estimated: number;
  isSingleImpact: boolean;
  type: "small" | "large";
  isVerifiedEstimatedFeatureEnabled: boolean;
};
function ImpactCardMobile({
  volume,
  unit,
  verified,
  estimated,
  type,
  isSingleImpact,
  isVerifiedEstimatedFeatureEnabled,
}: Props) {
  const [cardData, setCardData] = React.useState(outcomeDataMapper(unit));

  useEffect(() => {
    setCardData(outcomeDataMapper(unit));
  }, [unit, type]);

  return (
    <Container type={type} data-testid={`impact-card-${type}-${unit}`}>
      <CardHeader>
        {type === "large" && !isSingleImpact && <TopImpactTag />}
      </CardHeader>
      <CardContent>
        <IconContainer>
          <Icon
            size="large"
            icon={cardData?.icon}
            description={cardData?.unit}
          />
        </IconContainer>
        <DataContainer>
          <Volume>
            {volume > 999999
              ? `${formatValueToShort(volume)}*`
              : volume.toLocaleString()}
          </Volume>
          {volume > 999999 && (
            <VolumeDetailed>{`*${volume.toLocaleString()}`}</VolumeDetailed>
          )}
          <Description>{cardData?.description}</Description>
          {isVerifiedEstimatedFeatureEnabled && (
            <VerifiedEstimatedContainer>
              <VerifiedContainer>
                <StyledLabelText>Verified</StyledLabelText>
                <StyledH4>{`${verified}%`}</StyledH4>
              </VerifiedContainer>
              <EstimatedContainer>
                <StyledLabelText>Estimated</StyledLabelText>
                <StyledH4>{`${estimated}%`}</StyledH4>
              </EstimatedContainer>
            </VerifiedEstimatedContainer>
          )}
        </DataContainer>
      </CardContent>
    </Container>
  );
}

export default ImpactCardMobile;
