import React from "react";
import "react-tooltip/dist/react-tooltip.css";
import styled from "styled-components";
import { FlexColumn, FlexRow, StyledH2 } from "../../styles";
import CoBenefitCard from "./CoBenefitCard";
import books from "../../assets/icons/books.png";
import marketstand from "../../assets/icons/market-stand.png";

type CoBenefit = {
  unit: string;
  value: number;
};

type CoBenefitsResponse = Array<CoBenefit>;

const getIconForUnit = (unit: string): string => {
  const iconMap: { [key: string]: string } = {
    "Hours of Education": books,
    "Hours of Employment": marketstand,
  };
  return iconMap[unit];
};

export const Container = styled(FlexRow)`
  flex-wrap: wrap;
  margin-top: 8px;
  margin-bottom: 64px;
  justify-content: center;
  align-content: center;
  height: 100%;
  align-items: center;
`;

const CoBenefitsHeader = styled(StyledH2)`
  @media (max-width: 428px) {
    padding-top: 24px;
    font-size: 24px;
  }

  @media (min-width: 429px) {
    text-align: left;
    padding-top: 32px;
    padding-bottom: 32px;
    margin-left: 85px;
  }
`;

export const StyledWrapper = styled(FlexColumn)`
  height: auto;
  padding-left: 8px;
  margin-top: 32px;
  margin-bottom: 32px;
  padding-right: 8px;
  background-color: #f7f7f7;
  border-radius: 16px;
`;

export function shouldShowCoBenefit(coBenefit: CoBenefit): boolean {
  if (coBenefit.unit === "Hours of Education" && coBenefit.value >= 5) {
    return true;
  }
  if (coBenefit.unit === "Hours of Employment" && coBenefit.value >= 1) {
    return true;
  }
  return false;
}

export function formatVolume(
  value: number,
  unit: string,
): { hours: number; minutes: number; displayUnit: string } {
  const hours = Math.floor(value);
  const minutes = Math.floor((value - hours) * 60);
  const displayUnit =
    unit === "Hours of Education" ? "of Education" : "of Employment";
  return { hours, minutes, displayUnit };
}

export function CoBenefits({
  coBenefitList,
}: {
  coBenefitList: CoBenefitsResponse;
}) {
  if (coBenefitList.length === 0) return null;
  const visibleCoBenefitList = coBenefitList.filter(shouldShowCoBenefit);

  return (
    <StyledWrapper>
      <CoBenefitsHeader>Co-benefits</CoBenefitsHeader>
      <Container>
        {visibleCoBenefitList.map((coBenefit: CoBenefit) => {
          const { hours, minutes, displayUnit } = formatVolume(
            coBenefit.value,
            coBenefit.unit,
          );
          return (
            <CoBenefitCard
              key={coBenefit.unit}
              icon={getIconForUnit(coBenefit.unit)}
              unit={displayUnit}
              hours={hours}
              minutes={minutes}
            />
          );
        })}
      </Container>
    </StyledWrapper>
  );
}

export default CoBenefits;
